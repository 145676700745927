.menu {
  position: relative;
  display: flex;

  .menu-button {
    height: 32px;
    width: 32px;
    padding: 3px;
    cursor: pointer;
    border-radius: 6px;

    transition: all 0.3s ease-in;
  }

  .menu-list {
    position: absolute;
    top: 49px;
    // min-width: 220px;
    margin: 0;
    padding-top: 10px;
    padding-bottom: 17px;
    z-index: 9;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    // height: auto;

    background: #fbfbfb;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 10px 10px;

    transition: all 0.3s ease-out;

    button,
    .button-link,
    div {
      padding: 10px 28px;
    }

    hr {
      margin: 10px 20px;
      align-self: stretch;
      border: 1px solid #a6a6ac;
    }
  }
}

.menu.menu-right {
  .menu-list {
    right: 0;
  }
}

.menu.menu-left {
  .menu-list {
    left: 0;
  }
}

.menu.menu-loading {
  .menu-list {
    opacity: 0;
  }
}

.menu.menu-open {
  .menu-list {
    // max-height: 300px;
  }

  .menu-button {
    width: 33px;
    height: 33px;
    padding: 5px;
    background: #d9d9d9;
  }
}

.menu.menu-closed {
  .menu-list {
    height: 0;
    padding: 0;
  }
}

@media screen and (max-width: 540px) {
  .menu {
    .menu-list {
      position: fixed;
      margin-top: 15px;
    }
  }
}
