.input {
  background: #ffffff;
  border: 2px solid #a6a6ac;
  border-radius: 4px;
  padding: 10px 16px;

  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  &__white-smoke {
    background: #f7f7f7;
    transition: 0.1s all;
    &:focus {
      border-color: rgba(67, 42, 224, 1);
    }
  }
  &__medium {
    border-width: 1px;
    height: 40px;
  }
}

.input::placeholder {
  color: #a6a6ac;
}
