.button-group {
  color: #a6a6ac;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.button,
.button-link {
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  white-space: nowrap;
}

.button {
  color: #ffffff;
  background: #151d60;
  padding: 10px 20px;
  border: none;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  &-large {
    padding: 16px 20px;
  }
  &-outline {
    border: 2px solid rgba(242, 242, 255, 1);
    background: transparent;
  }
}

.button-link {
  color: #121639;
  padding: 1px 6px;
  border: none;
  background: none;
  box-shadow: none;
}

.button-link.muted {
  cursor: auto;
  color: #a6a6ac;
}

.button.primary {
  background: #432ae0;
  box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.25);
}

.button.success {
  background: #0bc196;
  box-shadow: none;
}

.button.error {
  background: #b60000;
  box-shadow: none;
}
.button.grey {
  background: #999999;
  box-shadow: none;
}

.button.submitting {
  cursor: wait;
  box-shadow: none;
}

.button.muted,
.button:disabled,
.button[disabled] {
  cursor: auto;
  background: #e1e1e1;
  box-shadow: none;
}
