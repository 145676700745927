.home {
  .hero-area {
    // background-image: url("../../public/img/home/hero-mb.svg");
    background-image: url("../../public/img/home/hero-tb.svg");
    background-repeat: no-repeat;
    background-position: top -130px center;
    // background-size: contain;
    @media (min-width: 540px) {
      background-image: url("../../public/img/home/hero-tb.svg");
      background-position: top -150px center;
    }

    @media (min-width: 800px) {
      background-image: none;
    }
  }
  .thrive-work {
    .logo {
      width: 50px;
      @media (min-width: 540px) {
        width: 100px;
      }
    }
    .step-body {
      svg {
        scale: 0.5;
        @media (min-width: 540px) {
          scale: 1;
        }
      }
    }
  }
}
