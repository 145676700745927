.heading {
  .underline-anim {
    position: relative;
    img.candidate {
      position: absolute;
      opacity: 0;
      width: 100%;
      bottom: -15%;
      left: 0;
      transform-origin: 0% 10%;
      transform: translate(-0.04px, 0.45px) rotate(359.1deg);
      animation: visible 0.8s cubic-bezier(0.65, 0, 0.35, 1) forwards;
      animation-delay: 0.36s;
    }
  }
  @keyframes visible {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
.right-anim-area {
  margin: 0px auto 0;
  max-width: 543px;
  position: relative;
  transform: perspective(400px);
  transform-origin: 50% 50%;
  * {
    font-family: "Arial", sans-serif;
  }

  .asset-1-screen {
    display: inline-block;
    overflow: hidden;
    width: 100%;
    .topbar {
      position: absolute;
      top: 0;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      left: 0.8%;
      right: 0;
      z-index: 99;
      width: 98.5%;
    }

    .left-bar {
      top: 6%;
      width: 14%;
      left: 4%;
    }

    .client-space {
      top: 12%;
      width: 37%;
      left: 20%;
      text-align: left;

      .client-head {
        font-weight: 700;
        font-size: 9px;
        line-height: 9px;
        margin: 0 0 5px;
        display: block;
      }

      .single-client-card {
        margin-bottom: 6px;

        img {
          filter: drop-shadow(0px 0.86px 0.86px rgba(0, 0, 0, 0.16));
        }
      }
      max-height: 86%;
      overflow: hidden;
      @media (max-width: 1200px) {
        // width: 34%;
        left: 20%;
      }
    }

    .right-chat-main {
      top: 12%;
      width: 28%;
      max-height: 86%;
      overflow: hidden;
      left: 60%;
      filter: drop-shadow(0px 3.02px 4.53px rgba(0, 0, 0, 0.16));

      .chats {
        width: 100%;
        top: 20%;
        padding: 0 7px;

        .single-chat {
          margin-bottom: 3px;

          &.chat-right {
            margin-left: auto;
          }

          &.message-box {
            margin: 8px 0 0;

            img {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .asset-2 {
    bottom: -40px;
    right: -45px;
    max-width: 265px;
    width: 100%;

    .salary-guidance-box {
      .sg-box-body {
        .sg-body-content {
          background: #f2f2f2;
          box-shadow: 0px 2.32px 4.64px rgba(0, 0, 0, 0.12);
          border-radius: 5.73555px;
          padding: 10px;

          .job-title {
            background: #e1e1e1;
            display: block;
            margin: 0 auto 10px;
            border-radius: 26px;
            width: 61.5%;
          }

          .salary-range {
            width: 90%;
            margin: 0 auto;
          }

          .bottom-buttons {
            margin: 25px auto 0;
            width: 100%;
          }
        }
      }
    }

    @media (max-width: 1200px) {
      right: -10px;
      max-width: 50%;
    }
  }
  .white-asset-box {
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0px 5.15px 9px rgba(0, 0, 0, 0.16);

    .sg-box-head {
      background-color: #151d60;
      text-align: center;
      color: #fff;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      padding: 5px 0;
    }

    .sg-box-body {
      padding: 12px 11px 20px;
      @media (max-width: 1200px) {
        padding: 9px 11px 18px;
      }
      @media (max-width: 500px) {
        padding: 4px 11px 4px;
      }
    }
  }

  .asset-3 {
    max-width: 321px;
    width: 100%;
    bottom: -40px;
    left: -25px;

    .white-asset-box {
      width: 100%;
      max-width: 100%;

      .sg-box-body {
        padding: 21px 11px 21px;
        @media (max-width: 1200px) {
          padding: 26px 11px 27px;
        }
        @media (max-width: 767px) {
          padding: 24px 11px 24px;
        }
        .pi-content-boxes {
          display: flex;
          justify-content: center;
          gap: 24px;
        }
      }
    }

    @media (max-width: 1200px) {
      max-width: 50%;
      left: -12px;
    }
  }
}
