@tailwind base;
@tailwind components;
@tailwind utilities;

@import "variables";
@import "button";
@import "input";
@import "menu";

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  outline: none;
}

html {
  background: #fff;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

body {
  font-family: $fontFamily;
  // font-size: 14px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  color: #121639;
  line-height: 1.6;
  letter-spacing: -0.078px;
}

.row {
  display: flex;
  margin-right: -10px;
  margin-left: -10px;
  flex-wrap: wrap;
}
.col {
  padding-right: 10px;
  padding-left: 10px;
}

.container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 32px;
  padding-right: 32px;
  @media (min-width: 375px) {
    max-width: 100%;
  }
  @media (min-width: 540px) {
    padding-left: 40px;
    padding-right: 40px;
    max-width: 100%;
  }
  @media (min-width: 800px) {
    max-width: 1200px;
  }
}
// .left-area {
//   background-color: #f8f8f8;
//   width: 100%;
//   height: 600px;
// }

// global text styles

h1 {
  font-size: 32px;
  font-weight: 700;
  color: #383838;
  margin-bottom: 10px;
  @media (min-width: 540px) {
    font-size: 40px;
  }
}

p {
  font-size: 16px;
  line-height: 25.6px;
  font-weight: 500;
  color: #696969;
  @media (min-width: 540px) {
    font-size: 20px;
    line-height: 32px;
  }
}

.heading {
  font-weight: 700;
  &-1 {
    color: #383838;
    font-size: 36px;
    line-height: 36px;
    @media screen and (min-width: 540px) {
      font-size: 40px;
      line-height: 1;
    }
    @media screen and (min-width: 800px) {
      font-size: 54px;
      line-height: 56px;
    }
  }
  &-2 {
    font-size: 24px;
    font-weight: 700;
    color: #383838;
    margin-bottom: 10px;
    @media (min-width: 540px) {
      font-size: 32px;
    }
  }
  &-3 {
    font-size: 40px;
    line-height: 50px;
    font-weight: 700;
    color: #1f1f26;
    @media (min-width: 800px) {
      font-size: 54px;
      line-height: 68px;
    }
  }
}

.fix-animation-z {
  transform: translate3d(0, 0, 0);
}
